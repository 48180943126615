import React from 'react';

import {Button} from '../components';

export const CookieBanner = ({
  showCookieConsent,
  onAcceptCookies: onAcceptCookiesProp,
  onDeclineCookies: onDeclineCookiesProp,
}: {
  showCookieConsent: boolean;
  onAcceptCookies: () => void;
  onDeclineCookies: () => void;
}) => {
  const [styling, setStyling] = React.useState('');
  const [mounted, setMounted] = React.useState(showCookieConsent);

  const onAccept = () => {
    onAcceptCookiesProp();
    setStyling('animate-fadeOut');
  };

  const onDecline = () => {
    onDeclineCookiesProp();
    setStyling('animate-fadeOut');
  };

  React.useEffect(() => {
    let timer: number;
    if (!showCookieConsent) {
      timer = window.setTimeout(() => {
        setMounted(false);
      }, 500);
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [showCookieConsent]);

  if (!mounted) {
    return null;
  }

  return (
    <div className={`fixed bottom-0 bg-background w-full z-40 ${styling}`}>
      <div className="sm:flex justify-between md:justify-center items-center gap-10 md:gap-14 mx-4 mt-3 sm:mt-auto sm:mx-8">
        <p className="text-smallbodymob md:text-smallbody">
          🍪 We use cookies to enhance your experience and improve our product.
        </p>
        <div className="justify-between sm:justify-center sm:min-w-fit py-3 flex gap-4">
          <div className="w-full sm:w-auto">
            <Button label="I accept" onClick={onAccept} fullWidth />
          </div>
          <div className="w-full sm:w-auto">
            <Button
              label="Decline"
              onClick={onDecline}
              type="secondary"
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};
