import * as React from 'react';
import {Icon} from '../../components/common/Icon';
import {colors} from '../../theme';
import {Person} from '../../types';
import {GatsbyImage} from 'gatsby-plugin-image';

export const FlipCard = ({image, name, description, pronouns}: Person) => {
  const [flip, setFlip] = React.useState(false);
  return (
    <div
      style={{
        backgroundColor: colors.white,
      }}
      className={`${
        flip ? 'animate-flip' : 'animate-flipBack'
      } flex h-full rounded-[2rem] bg-cover ${
        flip ? 'items-start' : 'items-end'
      } relative cursor-pointer`}
      id="flipcard">
      {flip ? (
        <div className="items-center w-full px-8 py-6">
          <p className="text-black text-subtitlelarge font-title pb-4">
            {name}
          </p>
          <p className="text-black text-caption md:text-smallbody max-h-[18rem] md:max-h-[24rem] overflow-y-auto">
            {description}
          </p>
          {pronouns && (
            <p className="text-black text-caption md:text-smallbody pt-4">
              preferred pronouns: {pronouns}
            </p>
          )}
          <div
            className="absolute bottom-6 right-9"
            onClick={e => {
              setFlip(!flip);
            }}
            onTouchStart={e => {
              e.stopPropagation();
            }}>
            <Icon
              iconName={'close'}
              fill={colors.sandwisp}
              height={57}
              width={57}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-full">
          {image && (
            <GatsbyImage
              alt={image.alt}
              image={image.gatsbyImageData}
              className="h-full w-full rounded-[2rem] z-0"
              draggable={false}
            />
          )}
          <div className="flex w-full justify-between items-center px-9 bottom-6 z-10 absolute">
            <h3 className="text-white text-subtitlelarge font-title">{name}</h3>
            <div
              onClick={() => {
                setFlip(!flip);
              }}
              onTouchStart={e => {
                e.stopPropagation();
              }}>
              <Icon
                iconName={'plus'}
                fill={colors.sandwisp}
                height={57}
                width={57}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
