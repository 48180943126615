import * as React from 'react';
import {Link as GatsbyLink} from 'gatsby';

import {ButtonTracking, Page} from '../../types';
import {getContentPath} from '../../module/data';
import {Tracker} from '../../module/analytics';

export interface ILink {
  linkTo?: Page;
  href?: string;
  className?: string;
  external?: boolean;
  articleLink?: {
    isParentsArticle?: boolean;
    categoryTitle: string;
    articleTitle?: string;
  };
  questionLink?: {
    categoryTitle: string;
    questionTitle?: string;
  };
  tracking?: ButtonTracking;
}

// eslint-disable-next-line react/display-name
export const Link = React.forwardRef(
  (
    {
      children,
      linkTo,
      href,
      className,
      external,
      articleLink,
      questionLink,
      tracking,
      ...otherProps
    }: React.PropsWithChildren<ILink>,
    ref: any,
  ) => {
    const to = React.useMemo(() => {
      let toPath = '';
      if (linkTo) {
        toPath = linkTo === 'home' ? '/' : `/${linkTo}`;
      } else if (articleLink) {
        if (articleLink.articleTitle) {
          toPath = getContentPath({
            contentType: articleLink.isParentsArticle
              ? 'parentsArticle'
              : 'article',
            categoryTitle: articleLink.categoryTitle,
            articleTitle: articleLink.articleTitle,
          });
        } else {
          toPath = getContentPath({
            contentType: 'category',
            categoryTitle: articleLink.categoryTitle,
          });
        }
      } else if (questionLink) {
        if (questionLink.questionTitle) {
          toPath = getContentPath({
            contentType: 'question',
            categoryTitle: questionLink.categoryTitle,
            questionTitle: questionLink.questionTitle,
          });
        } else {
          toPath = getContentPath({
            contentType: 'questionCategory',
            categoryTitle: questionLink.categoryTitle,
          });
        }
      }
      return toPath;
    }, [linkTo]);

    if (href) {
      const targetProps = external
        ? {
            href,
            target: '_blank',
            rel: 'noreferrer',
          }
        : {href};
      return (
        <a
          className={className}
          {...targetProps}
          {...otherProps}
          onClick={() => {
            if (
              tracking === ButtonTracking.AppStoreDownload &&
              Tracker.trackTapAppDownloadButton
            ) {
              Tracker.trackTapAppDownloadButton();
            }
          }}>
          {children}
        </a>
      );
    }

    return (
      <GatsbyLink ref={ref} to={to} className={className} {...otherProps}>
        {children}
      </GatsbyLink>
    );
  },
);
