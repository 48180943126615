import React, {ReactElement, createElement} from 'react';
import {graphql, useStaticQuery} from 'gatsby';

export type SEOTags = {
  tagName?: string;
  content?: string;
  attributes?: {
    property?: string;
    content?: string;
    name?: string;
  };
}[];

export type FaviconTags = {
  tagName?: string;
  attributes?: {[key: string]: string};
}[];

export function SEO({
  tags,
  children,
}: {
  tags?: SEOTags;
  children?: ReactElement;
}) {
  const data = useStaticQuery<Queries.SEOStaticQuery>(graphql`
    query SEOStatic {
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
    }
  `);
  const faviconTags = data?.datoCmsSite?.faviconMetaTags
    ?.tags as unknown as FaviconTags;

  return (
    <>
      {faviconTags.map(({tagName, attributes = {}}) => {
        if (tagName) {
          return createElement(tagName, attributes);
        }
        return null;
      })}
      {tags?.map(({tagName, content, attributes}, key) => {
        if (tagName === 'title' && content) {
          return <title key={key}>{content}</title>;
        } else if (tagName) {
          return createElement(tagName, attributes);
        }
        return null;
      })}
      {children}
    </>
  );
}
