import React, {useMemo} from 'react';
import aotd from '../../images/app-ot-day-light.png';
import {Button} from '../../components';
import {isBrowser} from '../../utils/isBrowser';
import {useScreenSize} from '../../utils/useScreenSize';

interface IDownloadBar {
  downloadCTA: string;
}

export const DownloadBar = ({downloadCTA}: IDownloadBar) => {
  const [scrollPosition, setScrollPosition] = React.useState(
    isBrowser ? window.scrollY : 0,
  );
  const scrollPositionRef = React.useRef(scrollPosition);
  const [styling, setStyling] = React.useState('hidden');

  const onHandleScroll = React.useCallback(() => {
    setScrollPosition(window.scrollY);
  }, []);

  React.useEffect(() => {
    if (isBrowser) {
      window.addEventListener('scroll', onHandleScroll);
      return () => {
        window.removeEventListener('scroll', onHandleScroll);
      };
    }
  }, []);

  const body = document.body;
  const html = document.documentElement;
  const pageHeight = Math.max(
    body.getBoundingClientRect().height,
    html.getBoundingClientRect().height,
  );

  const screenSize = useScreenSize();

  const isNearBottom = useMemo(() => {
    // this is to prevent the bounceback seen on mobile safari removing the download bar when the user scrolls to the bottom of the page, it provides a "buffer"
    //  as a side effect, it does mean the download bar stays visible when scrolling up from the bottom for for 700 pixels
    if (screenSize === 'small') {
      return scrollPosition > pageHeight - 700;
    }
  }, [scrollPosition]);

  React.useEffect(() => {
    if (scrollPosition !== scrollPositionRef.current) {
      if (isNearBottom) {
        setStyling('');
      } else if (scrollPosition > scrollPositionRef.current) {
        if (scrollPosition > 10) {
          setStyling('animate-fadeIn');
        }
      } else {
        setStyling('animate-fadeOut');
      }
    }
    scrollPositionRef.current = scrollPosition;
  }, [scrollPosition]);

  return (
    <div className={`fixed bottom-0 bg-primaryred w-full z-40 ${styling}`}>
      <div className="flex justify-between md:justify-center items-center gap-14 mx-4">
        <img
          src={aotd}
          alt="app of the day logo"
          className="h-12 hidden md:block"
        />
        <p className="text-smallbody font-semibold text-white">
          join the luna community today
        </p>
        <div className="min-w-fit py-3">
          <Button label={downloadCTA} linkTo="download" type="secondary" />
        </div>
      </div>
    </div>
  );
};
