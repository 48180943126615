import slugify from 'slugify';

interface IGetPath {
  contentType:
    | 'article'
    | 'category'
    | 'question'
    | 'questionCategory'
    | 'parentsArticle';
  categoryTitle?: string;
  articleTitle?: string;
  questionTitle?: string;
}

// we crudely copy getContentPath to "gatsby-node.js" as Netlify doesn't seem to support `.ts` gatsby-node files
export function getContentPath({
  contentType,
  categoryTitle = '',
  articleTitle = '',
  questionTitle = '',
}: IGetPath) {
  const categorySlug = slugify(categoryTitle, {
    lower: true,
    trim: true,
  });
  if (contentType === 'category') {
    return `/articles/${categorySlug}`;
  } else if (contentType === 'questionCategory') {
    return `/questions/${categorySlug}`;
  } else if (contentType === 'article') {
    return `/articles/${categorySlug}/${slugify(articleTitle, {
      lower: true,
      trim: true,
    })}`;
  } else if (contentType === 'question') {
    return `/questions/${categorySlug}/${slugify(questionTitle.slice(0, 50), {
      lower: true,
      trim: true,
    })}`;
  } else if (contentType === 'parentsArticle') {
    return `/parents/${slugify(articleTitle, {
      lower: true,
      trim: true,
    })}`;
  }
  return '';
}
