import * as React from 'react';
import {Icon, Link} from '../../components/common';
import {colors} from '../../theme';
import {NavDownloadCTA} from './NavDownloadCTA';
import {Socials} from './Socials';

interface IBurgerMenu {
  onClick: () => void;
  mode: string;
}

export const BurgerMenu = ({onClick, mode}: IBurgerMenu) => {
  const fillColour = mode === 'light' ? colors.background : colors.black;
  return (
    <div
      onClick={onClick}
      className="visible md:hidden px-2 sm:px-6 sm:pr-10 py-2">
      <Icon iconName={'burger'} color={fillColour} />
    </div>
  );
};

interface IBurgerPanel {
  open: boolean;
  onClose: () => void;
}
export const BurgerPanel = ({open, onClose}: IBurgerPanel) => {
  return (
    <div className={`md:hidden`}>
      <div
        id="blurredBackdrop"
        className={`bg-black fixed top-0 bottom-0 left-0 right-0 bg-opacity-40 ${
          open ? 'visible' : 'hidden'
        }`}
        onClick={onClose}
      />
      <div
        className={`fixed bg-bodyimage top-0 bottom-0 h-full left-0 w-2/3 p-6 rounded-r-xl z-50 transform ease-in duration-500 ${
          open ? 'translate-x-0 ' : '-translate-x-full'
        }`}>
        <div onClick={onClose}>
          <Icon
            iconName={'close'}
            color={colors.background}
            height={28}
            width={28}
          />
        </div>
        <div className="flex-col justify-between pl-3 text-black text-smallbody">
          <h3 className="text-h3 font-semibold pb-4">menu</h3>
          <div onClick={onClose}>
            <Link linkTo="home" className="hover:text-background">
              Home
            </Link>
          </div>
          <div onClick={onClose} className="mt-1">
            <Link linkTo="premium" className="hover:text-background">
              luna subscriptions
            </Link>
          </div>
          <div onClick={onClose} className="mt-1">
            <Link linkTo="about-us" className="hover:text-background">
              About us
            </Link>
          </div>
          <div onClick={onClose} className="mt-1">
            <Link linkTo="teens" className="hover:text-background">
              I'm a teen
            </Link>
          </div>
          <div onClick={onClose} className="mt-1">
            <Link linkTo="parents" className="hover:text-background">
              I'm a parent/guardian
            </Link>
          </div>
          <div onClick={onClose} className="mt-1">
            <Link linkTo="schools" className="hover:text-background">
              Schools
            </Link>
          </div>
          <div onClick={onClose} className="mt-1">
            <Link linkTo="work-with-us" className="hover:text-background">
              Work with us
            </Link>
          </div>

          <div onClick={onClose} className="mt-1">
            <Link linkTo="shop" className="hover:text-background">
              Shop
            </Link>
          </div>

          <div className="fixed bottom-0 mb-8">
            <div onClick={onClose}>
              <NavDownloadCTA className="bg-none ml-0 pb-2" />
            </div>
            <Socials />
          </div>
        </div>
      </div>
    </div>
  );
};
