import * as React from 'react';
import {Link} from '../../components/common';

interface ISocialCard {
  url: string;
  socialImg: string;
}

export const SocialCard = ({url, socialImg}: ISocialCard) => {
  return (
    <Link external={true} href={url}>
      <div
        style={{
          backgroundImage: `url(${socialImg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
        className="h-8 lg:h-12 w-8 lg:w-12 rounded-lg"
      />
    </Link>
  );
};
