import React, {useEffect, useState} from 'react';
import {useRef} from 'react';
import {Icon} from './Icon';

interface IModal {
  isOpen: boolean;
  hasCloseBtn?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const Modal = ({isOpen, hasCloseBtn, onClose, children}: IModal) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  const modalRef = useRef<HTMLDialogElement | null>(null);
  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      handleCloseModal();
    }
  };

  return (
    <dialog
      ref={modalRef}
      onKeyDown={handleKeyDown}
      className="bg-background backdrop:bg-bluerice backdrop:opacity-80 py-8 px-14 rounded-[32px] text-black lg:w-2/3 3xl:w-1/3">
      {hasCloseBtn && (
        <div className="static pb-6">
          <button
            className="modal-close-btn absolute right-0 top-0 m-4"
            onClick={handleCloseModal}>
            <Icon iconName={'close'} fill="#DEDEE2" height={34} width={34} />
          </button>
        </div>
      )}
      {children}
    </dialog>
  );
};
