import * as React from 'react';

export function Logo({mode}: {mode: 'light' | 'dark'}) {
  const color = mode === 'light' ? '#FFFFFF' : '#E68E86';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="26"
      viewBox="0 0 61 26"
      fill="none">
      <title>luna logo</title>
      <g clipPath="url(#clip0_931_11792)">
        <path
          d="M3.58131 0.430908H0.337402V25.2968H3.58131V0.430908Z"
          fill={color}
        />
        <path
          d="M0.804688 24.8387V0.88916H3.11436V24.8387H0.804688Z"
          fill={color}
        />
        <path
          d="M13.2109 25.7177C11.267 25.7177 4.92383 25.1082 4.92383 17.2654V6.67725H8.16774V17.0067C8.16774 20.8796 9.81724 22.7624 13.2107 22.7624C16.3743 22.7624 18.4186 20.4397 18.4186 16.845V6.67725H21.6625V25.297H18.5505V23.5189C17.3253 24.9313 15.4492 25.7177 13.2107 25.7177H13.2109Z"
          fill={color}
        />
        <path
          d="M19.018 24.8386V22.0877H18.952C17.929 24.1265 15.8175 25.2593 13.2109 25.2593C9.35051 25.2593 5.39111 23.2527 5.39111 17.2654V7.1355H7.70078V17.0066C7.70078 21.0197 9.44949 23.2204 13.2109 23.2204C16.3124 23.2204 18.8859 21.0845 18.8859 16.8447V7.1355H21.1956V24.8386H19.018Z"
          fill={color}
        />
        <path
          d="M36.731 25.297V14.9676C36.731 11.4516 34.7126 9.17958 31.5891 9.17958C28.4657 9.17958 26.4143 11.4406 26.4143 14.8057V25.2968H23.1704V6.67727H26.4143V8.20018C27.6956 6.94656 29.5387 6.22412 31.5562 6.22412C36.6704 6.22412 39.9752 9.65605 39.9752 14.9676V25.297H36.7312H36.731Z"
          fill={color}
        />
        <path
          d="M37.1985 24.8387V14.9676C37.1985 10.9867 34.757 8.72122 31.5894 8.72122C28.092 8.72122 25.9474 11.3428 25.9474 14.8057V24.8387H23.6377V7.13552H25.9474V9.40105H26.0134C27.168 7.68576 29.2139 6.68237 31.5565 6.68237C36.5386 6.68237 39.5082 10.0482 39.5082 14.9676V24.8387H37.1985Z"
          fill={color}
        />
        <path
          d="M50.7927 25.7177C45.0912 25.7177 40.625 21.4223 40.625 15.9385C40.625 10.4547 45.0912 6.22412 50.7927 6.22412C53.4667 6.22412 55.8159 7.2098 57.4192 8.96702V6.67727H60.6632V25.297H57.4192V22.9752C55.7912 24.7111 53.3983 25.7179 50.7927 25.7179V25.7177ZM50.7595 9.17958C46.8312 9.17958 43.8689 12.127 43.8689 16.0356C43.8689 19.9442 46.8454 22.7622 50.7927 22.7622C54.5194 22.7622 57.5514 19.7301 57.5514 16.0033C57.5514 12.2765 54.5047 9.17958 50.7597 9.17958H50.7595Z"
          fill={color}
        />
        <path
          d="M57.8865 24.8387V21.7316H57.8205C56.3358 23.9324 53.7292 25.2593 50.7927 25.2593C45.3815 25.2593 41.0923 21.2462 41.0923 15.9384C41.0923 10.6307 45.5134 6.68237 50.7927 6.68237C53.7952 6.68237 56.3687 8.00931 57.8205 10.2423H57.8865V7.1353H60.1962V24.8384H57.8865V24.8387ZM58.0184 16.0032C58.0184 12.0547 54.8509 8.72122 50.7596 8.72122C46.6683 8.72122 43.4017 11.8282 43.4017 16.0355C43.4017 20.2428 46.5361 23.2205 50.7927 23.2205C54.8179 23.2205 58.0184 19.9841 58.0184 16.0032Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_931_11792">
          <rect width="61" height="26" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
