import * as React from 'react';

interface ITag {
  color: string;
  title: string;
}

export const Tag = ({color, title}: ITag) => {
  return (
    <div
      className={`rounded-full text-caption px-3 py-1 w-fit h-8 flex items-center`}
      style={{backgroundColor: color}}>
      {title}
    </div>
  );
};
