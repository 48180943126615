import * as React from 'react';

interface ITickerList {
  itemList: {name: string; img: string}[];
}

export const TickerList = ({itemList}: ITickerList) => {
  let animateLengthFirst = 'animate-[ticker_10s_linear_infinite]';
  let animateLengthSecond = 'animate-[ticker2_10s_linear_infinite]';

  if (itemList.length > 7) {
    animateLengthFirst = 'animate-[ticker_20s_linear_infinite]';
    animateLengthSecond = 'animate-[ticker2_20s_linear_infinite]';
  }

  return (
    <div className="flex whitespace-nowrap overflow-x-hidden">
      <div className="relative">
        <ul className={`flex ${animateLengthFirst}`}>
          {itemList.map((p, i) => (
            <li className="mx-4" key={i}>
              <img
                src={p.img}
                key={i}
                className="min-h-10 max-h-10 max-w-none"
                alt={`a logo of ${p.name}`}
              />
            </li>
          ))}
        </ul>
        <ul className={`flex absolute top-0 ${animateLengthSecond}`}>
          {itemList.map((p, i) => (
            <li className="mx-4" key={i}>
              <img
                src={p.img}
                key={i}
                className="min-h-10 max-h-10 max-w-none"
                alt={`a logo of ${p.name}`}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
