import * as React from 'react';
import {Link} from './Link';
import {Page} from '../../types';

interface IImageCard {
  imgSrc: string;
  linkTo: Page;
  children?: React.ReactNode;
  height?: string;
  width?: string;
}
export const ImageCard = ({
  imgSrc,
  linkTo,
  children,
  height,
  width,
}: IImageCard) => {
  const cardHeight = height ? `${height}` : 'min-h-full';
  const cardWidth = width ? `${width}` : 'max-w-full';
  return (
    <Link className={'flex-1'} linkTo={linkTo}>
      <div
        style={{
          backgroundImage: `url(${imgSrc})`,
        }}
        className={`flex ${cardHeight} ${cardWidth} justify-center items-center rounded-[2rem] bg-cover`}>
        {children}
      </div>
    </Link>
  );
};
