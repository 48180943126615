import {useCallback, useEffect, useState} from 'react';
import {isBrowser} from './isBrowser';

export const usePageWidth = () => {
  const [pageWidth, setPageWidth] = useState(isBrowser ? window.innerWidth : 0);

  const onHandleRresize = useCallback(() => {
    setPageWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('resize', onHandleRresize);
      return () => {
        window.removeEventListener('resize', onHandleRresize);
      };
    }
  }, []);

  return pageWidth;
};
