export type ThemeColor =
  | 'primary'
  | 'secondary'
  | 'weak'
  | 'divider'
  | 'black'
  | 'white'
  | 'background'
  | 'sandwisp'
  | 'sandwispcontrast'
  | 'bluerice'
  | 'primaryred'
  | 'creole'
  | 'hotcalypso'
  | 'femalehealth'
  | 'skincare'
  | 'gender'
  | 'mentalhealth'
  | 'sleep'
  | 'relationships'
  | 'hair'
  | 'sex'
  | 'periods'
  | 'sexualhealth'
  | 'nutrition'
  | 'bodyimage'
  | 'navigating'
  | 'growing';

export const colors = {
  primary: '#E68E86',
  secondary: '#F4E09F',
  weak: '#7a7a7a',
  divider: '#DEDEE2',
  black: '#424242',
  white: '#FFFFFF',
  background: '#FAF7F4',
  sandwisp: '#F4E09F',
  sandwispcontrast: '#F9E7AC',
  bluerice: '#A6C9E4',
  primaryred: '#E68E86',
  creole: '#DCAB8D',
  hotcalypso: '#E68E86',
  femalehealth: '#F4E09F',
  skincare: '#EAB99B',
  gender: '#B5D8BA',
  mentalhealth: '#B4D7F2',
  sleep: '#A7AEF1',
  relationships: '#A7F1ED',
  hair: '#F0F1A7',
  sex: '#F1A7B9',
  periods: '#D0A7F1',
  sexualhealth: '#FABF89',
  nutrition: '#D2DFB6',
  bodyimage: '#F1D1D1',
  navigating: '#B8E0D4',
  growing: '#DEDEE2',
};
