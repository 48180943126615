import * as React from 'react';
import {colors} from '../../theme';

type IconName =
  | 'external-link'
  | 'heart'
  | 'heart-filled'
  | 'search'
  | 'alert'
  | 'alert-filled'
  | 'delete'
  | 'chevron-down'
  | 'chevron-up'
  | 'burger'
  | 'close'
  | 'star'
  | 'partial-star'
  | 'plus'
  | 'left'
  | 'right'
  | 'tick-circle'
  | 'cart';

interface IIcon {
  iconName: IconName;
  width?: number | string;
  height?: number | string;
  color?: string;
  fill?: string;
  percentageFill?: number;
}

export function Icon({
  iconName,
  width,
  height,
  color = '#7E7E7E',
  fill = colors.white,
  percentageFill = 100,
}: IIcon) {
  const iconWidth = width || 24;
  const iconHeight = height || 24;
  if (iconName === 'external-link') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 11 12"
        fill="none">
        <g clipPath="url(#clip0_702_3333)">
          <path
            d="M4.58325 6.45848C4.78008 6.72162 5.03121 6.93936 5.31959 7.09691C5.60797 7.25447 5.92686 7.34816 6.25463 7.37163C6.58241 7.39511 6.9114 7.34782 7.21929 7.23296C7.52717 7.11811 7.80676 6.93839 8.03909 6.70598L9.41409 5.33098C9.83153 4.89877 10.0625 4.31989 10.0573 3.71902C10.0521 3.11815 9.81106 2.54337 9.38617 2.11848C8.96128 1.69359 8.3865 1.45257 7.78563 1.44735C7.18476 1.44213 6.60588 1.67312 6.17367 2.09056L5.38534 2.87431"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.41676 5.54157C6.21993 5.27842 5.9688 5.06069 5.68042 4.90314C5.39204 4.74558 5.07315 4.65189 4.74538 4.62841C4.4176 4.60494 4.08861 4.65223 3.78072 4.76708C3.47284 4.88193 3.19325 5.06166 2.96092 5.29407L1.58592 6.66907C1.16848 7.10128 0.937492 7.68016 0.942714 8.28103C0.947935 8.8819 1.18895 9.45667 1.61384 9.88157C2.03873 10.3065 2.61351 10.5475 3.21438 10.5527C3.81525 10.5579 4.39413 10.3269 4.82634 9.90948L5.61009 9.12573"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_702_3333">
            <rect
              width="11"
              height="11"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (iconName === 'heart' || iconName === 'heart-filled') {
    const fillColor = iconName === 'heart-filled' ? color : undefined;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M20.4578 4.59133C19.9691 4.08683 19.3889 3.68663 18.7503 3.41358C18.1117 3.14054 17.4272 3 16.7359 3C16.0446 3 15.3601 3.14054 14.7215 3.41358C14.0829 3.68663 13.5026 4.08683 13.0139 4.59133L11.9997 5.63785L10.9855 4.59133C9.99842 3.57276 8.6596 3.00053 7.26361 3.00053C5.86761 3.00053 4.52879 3.57276 3.54168 4.59133C2.55456 5.6099 2 6.99139 2 8.43187C2 9.87235 2.55456 11.2538 3.54168 12.2724L4.55588 13.3189L11.9997 21L19.4436 13.3189L20.4578 12.2724C20.9467 11.7681 21.3346 11.1694 21.5992 10.5105C21.8638 9.85148 22 9.14517 22 8.43187C22 7.71857 21.8638 7.01225 21.5992 6.35328C21.3346 5.69431 20.9467 5.09559 20.4578 4.59133Z"
          fill={fillColor}
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'search') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0004 20.9984L16.6504 16.6484"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'alert' || iconName === 'alert-filled') {
    const fillColor = iconName === 'alert-filled' ? color : undefined;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 16 18"
        fill="none">
        <path
          d="M12.5 6.00146C12.5 4.80799 12.0259 3.6634 11.182 2.81948C10.3381 1.97557 9.19347 1.50146 8 1.50146C6.80653 1.50146 5.66193 1.97557 4.81802 2.81948C3.97411 3.6634 3.5 4.80799 3.5 6.00146C3.5 11.2515 1.25 12.7515 1.25 12.7515H14.75C14.75 12.7515 12.5 11.2515 12.5 6.00146Z"
          stroke={color}
          fill={fillColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.29764 15.7515C9.16578 15.9788 8.97652 16.1675 8.74881 16.2986C8.52109 16.4298 8.26292 16.4988 8.00014 16.4988C7.73735 16.4988 7.47918 16.4298 7.25147 16.2986C7.02375 16.1675 6.83449 15.9788 6.70264 15.7515"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'delete') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 30"
        width={iconWidth}
        height={iconHeight}
        stroke={color}
        x="0px"
        y="0px">
        <path d="m20,5.25h-3.472l-.339-.944c-.282-1.213-1.255-2.056-2.385-2.056h-3.608c-1.129,0-2.103.843-2.385,2.056l-.339.944h-3.472c-.414,0-.75.336-.75.75s.336.75.75.75h1.25v12.25c0,1.517,1.233,2.75,2.75,2.75h8c1.517,0,2.75-1.233,2.75-2.75V6.75h1.25c.414,0,.75-.336.75-.75s-.336-.75-.75-.75Zm-10.761-.482l.027-.096c.116-.543.499-.922.93-.922h3.608c.431,0,.813.379.93.922l.2.578h-5.868l.173-.482Zm8.011,14.232c0,.689-.561,1.25-1.25,1.25h-8c-.689,0-1.25-.561-1.25-1.25V6.75h10.5v12.25Zm-8-3v-5c0-.414.336-.75.75-.75s.75.336.75.75v5c0,.414-.336.75-.75.75s-.75-.336-.75-.75Zm4,0v-5c0-.414.336-.75.75-.75s.75.336.75.75v5c0,.414-.336.75-.75.75s-.75-.336-.75-.75Z" />
      </svg>
    );
  } else if (iconName === 'chevron-up') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 28 28"
        fill="none">
        <path
          d="M21.4004 18L14.4004 11L7.40039 18"
          stroke={color}
          strokeWidth="2.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'chevron-down') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 28 28"
        fill="none">
        <path
          d="M6.60156 10L13.6016 17L20.6016 10"
          stroke={color}
          strokeWidth="2.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'burger') {
    return (
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21 10.5H3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 6.5H3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 14.5H3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 18.5H3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'close') {
    return (
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="57" height="57" rx="28.5" fill={fill} />
        <path
          d="M35.8398 20.377L20.3804 35.8364"
          stroke="#464646"
          strokeWidth="3.04"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3809 20.377L35.8403 35.8364"
          stroke="#464646"
          strokeWidth="3.04"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'partial-star') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 19 18"
        fill="none">
        <defs>
          <linearGradient id={'partialStar'} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={color} stopOpacity={1} />
            <stop
              offset={`${percentageFill}%`}
              stopColor={color}
              stopOpacity={1}
            />
            <stop
              offset={`${percentageFill}%`}
              stopColor={fill}
              stopOpacity={1}
            />
            <stop offset="100%" stopColor={fill} stopOpacity={1} />
          </linearGradient>
        </defs>
        <path
          d="M8.54894 0.927051C8.8483 0.00574017 10.1517 0.00573993 10.4511 0.927051L11.8574 5.25532C11.9913 5.66734 12.3752 5.9463 12.8085 5.9463H17.3595C18.3282 5.9463 18.731 7.18592 17.9473 7.75532L14.2654 10.4303C13.9149 10.685 13.7683 11.1364 13.9021 11.5484L15.3085 15.8766C15.6078 16.798 14.5533 17.5641 13.7696 16.9947L10.0878 14.3197C9.7373 14.065 9.2627 14.065 8.91221 14.3197L5.23037 16.9947C4.44665 17.5641 3.39217 16.798 3.69153 15.8766L5.09787 11.5484C5.23174 11.1364 5.08508 10.685 4.7346 10.4303L1.05275 7.75532C0.269035 7.18592 0.67181 5.9463 1.64053 5.9463H6.19155C6.62477 5.9463 7.00873 5.66734 7.1426 5.25532L8.54894 0.927051Z"
          fill={`url('#partialStar')`}
        />
      </svg>
    );
  } else if (iconName === 'star') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 19 18"
        fill="none">
        <path
          d="M8.54894 0.927051C8.8483 0.00574017 10.1517 0.00573993 10.4511 0.927051L11.8574 5.25532C11.9913 5.66734 12.3752 5.9463 12.8085 5.9463H17.3595C18.3282 5.9463 18.731 7.18592 17.9473 7.75532L14.2654 10.4303C13.9149 10.685 13.7683 11.1364 13.9021 11.5484L15.3085 15.8766C15.6078 16.798 14.5533 17.5641 13.7696 16.9947L10.0878 14.3197C9.7373 14.065 9.2627 14.065 8.91221 14.3197L5.23037 16.9947C4.44665 17.5641 3.39217 16.798 3.69153 15.8766L5.09787 11.5484C5.23174 11.1364 5.08508 10.685 4.7346 10.4303L1.05275 7.75532C0.269035 7.18592 0.67181 5.9463 1.64053 5.9463H6.19155C6.62477 5.9463 7.00873 5.66734 7.1426 5.25532L8.54894 0.927051Z"
          fill={fill}
        />
      </svg>
    );
  } else if (iconName === 'plus') {
    return (
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="57" height="57" rx="28.5" fill={fill} />
        <path
          d="M28.1113 17.1748V39.0378"
          stroke="#464646"
          strokeWidth="3.04"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.1797 28.1064H39.0427"
          stroke="#464646"
          strokeWidth="3.04"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'left') {
    return (
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 75 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="75" height="75" rx="37.5" fill={fill} />
        <path
          d="M43.6038 49.526L31.7617 37.6839L43.6038 25.8418"
          stroke={color}
          strokeWidth="3.94737"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'right') {
    return (
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 75 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          width="75"
          height="75"
          rx="37.5"
          transform="matrix(-1 0 0 1 75 0)"
          fill={fill}
        />
        <path
          d="M31.3962 49.526L43.2383 37.6839L31.3962 25.8418"
          stroke={color}
          strokeWidth="3.94737"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'tick-circle') {
    return (
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill={fill} />
        <path
          d="M17.1431 8L10.0717 16.0008L6.85742 12.3641"
          stroke={color}
          strokeWidth="1.66013"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconName === 'cart') {
    return (
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_403_2859)">
          <path
            d="M6.99994 24.0006C8.1045 24.0006 8.99993 23.1052 8.99993 22.0006C8.99993 20.896 8.1045 20.0006 6.99994 20.0006C5.89537 20.0006 4.99994 20.896 4.99994 22.0006C4.99994 23.1052 5.89537 24.0006 6.99994 24.0006Z"
            fill={fill}
          />
          <path
            d="M17 24.0006C18.1046 24.0006 19 23.1052 19 22.0006C19 20.896 18.1046 20.0006 17 20.0006C15.8954 20.0006 15 20.896 15 22.0006C15 23.1052 15.8954 24.0006 17 24.0006Z"
            fill={fill}
          />
          <path
            d="M23 3H21.0001V1C21.0001 0.734784 20.8947 0.48043 20.7072 0.292893C20.5196 0.105357 20.2653 0 20.0001 0C19.7348 0 19.4805 0.105357 19.2929 0.292893C19.1054 0.48043 19.0001 0.734784 19.0001 1V3H17.0001C16.7348 3 16.4805 3.10536 16.293 3.29289C16.1054 3.48043 16.0001 3.73478 16.0001 4C16.0001 4.26522 16.1054 4.51957 16.293 4.70711C16.4805 4.89464 16.7348 5 17.0001 5H19.0001V7C19.0001 7.26522 19.1054 7.51957 19.2929 7.70711C19.4805 7.89464 19.7348 8 20.0001 8C20.2653 8 20.5196 7.89464 20.7072 7.70711C20.8947 7.51957 21.0001 7.26522 21.0001 7V5H23C23.2653 5 23.5196 4.89464 23.7072 4.70711C23.8947 4.51957 24 4.26522 24 4C24 3.73478 23.8947 3.48043 23.7072 3.29289C23.5196 3.10536 23.2653 3 23 3Z"
            fill={fill}
          />
          <path
            d="M21.771 9.726C21.6417 9.70181 21.5089 9.70352 21.3803 9.73103C21.2516 9.75854 21.1297 9.81132 21.0216 9.88629C20.9136 9.96126 20.8214 10.0569 20.7506 10.1678C20.6798 10.2786 20.6316 10.4024 20.609 10.532C20.4843 11.2242 20.1203 11.8505 19.5808 12.3016C19.0412 12.7527 18.3603 12.9999 17.657 13H5.418L4.478 5H13C13.2652 5 13.5196 4.89464 13.7071 4.70711C13.8946 4.51957 14 4.26522 14 4C14 3.73478 13.8946 3.48043 13.7071 3.29289C13.5196 3.10536 13.2652 3 13 3H4.242L4.2 2.648C4.11382 1.9186 3.76306 1.24615 3.21419 0.758104C2.66532 0.270054 1.95647 0.000312836 1.222 0L1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H1.222C1.46693 2.00003 1.70334 2.08996 1.88637 2.25272C2.06941 2.41547 2.18634 2.63975 2.215 2.883L3.591 14.583C3.73385 15.7998 4.31848 16.9218 5.23391 17.736C6.14934 18.5502 7.33185 19 8.557 19H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18C20 17.7348 19.8946 17.4804 19.7071 17.2929C19.5196 17.1054 19.2652 17 19 17H8.557C7.93652 17.0001 7.33127 16.8078 6.82461 16.4497C6.31796 16.0915 5.93483 15.585 5.728 15H17.657C18.8291 15.0001 19.9641 14.5884 20.8635 13.8368C21.763 13.0852 22.3698 12.0415 22.578 10.888C22.6014 10.7587 22.599 10.6261 22.5711 10.4977C22.5432 10.3693 22.4902 10.2477 22.4153 10.1398C22.3403 10.0319 22.2449 9.93978 22.1343 9.86878C22.0238 9.79778 21.9003 9.74926 21.771 9.726Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_403_2859">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
