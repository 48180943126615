import * as React from 'react';

interface IImageHeader {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  imgSrc: string;
  titleClassName?: string;
}

export const ImageHeader = ({
  title,
  subtitle,
  children,
  imgSrc,
  titleClassName,
}: IImageHeader) => {
  const titleWidth = titleClassName
    ? `${titleClassName}`
    : 'max-w-[28rem] md:max-w-[49rem] ';

  return (
    <div
      className="relative h-[49rem] md:h-[59rem] bg-cover bg-[center] flex justify-center items-center"
      style={{
        backgroundImage: `url(${imgSrc})`,
      }}>
      <div
        className={`flex flex-col px-5 md:px-12 w-full h-fit items-center ${titleWidth}`}>
        <h1 className="text-white text-h2mob md:text-h2 font-title py-4 text-center">
          {title}
        </h1>
        <h2 className="text-white text-largebodymob md:text-largebody font-body text-center pt-4">
          {subtitle}
        </h2>
        {children}
      </div>
    </div>
  );
};
