import {AnalyticsTracker} from './AnalyticsTracker';

declare global {
  interface Window {
    // https://developers.facebook.com/docs/meta-pixel/reference#standard-events
    fbq: (a: string, b: string, params?: any) => void;
  }
}

function shouldTrack() {
  return (
    process.env.NODE_ENV === 'production' &&
    typeof window === 'object' &&
    typeof window.fbq === 'function'
  );
}

export const MetaTracker: AnalyticsTracker = {
  trackInitiateCheckout: ({productId}: {productId: string}) => {
    if (shouldTrack()) {
      window.fbq('track', 'InitiateCheckout', {content_ids: [productId]});
    }
  },

  trackLead: () => {
    if (shouldTrack()) {
      window.fbq('track', 'Lead');
    }
  },

  trackTapAppDownloadButton: () => {
    if (shouldTrack()) {
      window.fbq('trackCustom', 'TapAppStoreDownload');
    }
  },

  trackPurchase: ({
    productId,
    value,
    currency,
  }: {
    productId: string;
    value: number;
    currency: string;
  }) => {
    if (shouldTrack()) {
      window.fbq('track', 'Purchase', {
        content_ids: [productId],
        value,
        currency,
      });
    }
  },

  trackSubscribe: ({
    value,
    currency,
    recurringCost,
  }: {
    value: number;
    currency: string;
    recurringCost: number;
  }) => {
    if (shouldTrack()) {
      window.fbq('track', 'Subscribe', {
        value,
        currency,
        predicted_ltv: recurringCost,
      });
    }
  },
};

export function enableMetaTracking() {
  if (shouldTrack()) {
    window.fbq('consent', 'grant');
  }
}
