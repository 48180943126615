import React, {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
  useCallback,
} from 'react';
import {
  CookieConsentStatus,
  getCookieConsentStatusValue,
  setCookieConsentStatusValue,
} from '../module/localStorage';
import {gaOptOut} from '../module/analytics/google';
import {enableMetaTracking} from '../module/analytics/meta';

interface IStoreContext {
  sideMenuOpen: boolean;
  showCookieConsent: boolean;
  onSetSideMenuOpen: (open: boolean) => void;
  onAcceptCookies: () => void;
  onDeclineCookies: () => void;
}

const defaultContext: IStoreContext = {
  sideMenuOpen: false,
  showCookieConsent: false,
  onSetSideMenuOpen: () => {},
  onAcceptCookies: () => {},
  onDeclineCookies: () => {},
};

const StoreContext = createContext<IStoreContext>(defaultContext);

export function StoreProvider({children}: PropsWithChildren<unknown>) {
  const [sideMenuOpen, onSetSideMenuOpen] = useState(false);

  const [cookieConsentStatus, setCookieConsentStatus] =
    useState<CookieConsentStatus | null>(getCookieConsentStatusValue());

  const onAcceptCookies = useCallback(() => {
    setCookieConsentStatus('accepted');
    setCookieConsentStatusValue('accepted');
  }, []);

  const onDeclineCookies = useCallback(() => {
    setCookieConsentStatus('declined');
    setCookieConsentStatusValue('declined');
  }, []);

  React.useEffect(() => {
    if (cookieConsentStatus === 'declined') {
      gaOptOut();
    } else if (cookieConsentStatus === 'accepted') {
      enableMetaTracking();
    }
  }, [cookieConsentStatus]);

  const showCookieConsent = React.useMemo(() => {
    return cookieConsentStatus === null;
  }, [cookieConsentStatus]);

  return (
    <StoreContext.Provider
      value={{
        sideMenuOpen,
        showCookieConsent,
        onSetSideMenuOpen,
        onAcceptCookies,
        onDeclineCookies,
      }}>
      {children}
    </StoreContext.Provider>
  );
}

export function useStore() {
  return useContext(StoreContext);
}
