import {useEffect} from 'react';

interface ISetCarouselInterval {
  isSlideshowPaused: boolean;
  currentSlideIndex: number;
  lastSlideIndex: number;
  onSetCurrentSlideIndex: (number: number) => void;
  time: number;
}

export const useSetCarouselInterval = ({
  isSlideshowPaused,
  currentSlideIndex,
  lastSlideIndex,
  onSetCurrentSlideIndex,
  time,
}: ISetCarouselInterval) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isSlideshowPaused) {
        currentSlideIndex === lastSlideIndex - 1
          ? onSetCurrentSlideIndex(0)
          : onSetCurrentSlideIndex(currentSlideIndex + 1);
      }
    }, time);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });
};
