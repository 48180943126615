export const appStoreLink =
  'https://apps.apple.com/gb/app/we-are-luna/id1632059799';

export const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.lunalife.luna';

export const redeemCodeDynamicLink = 'https://link.weareluna.app/CLSk';

const globalMargins = ' max-w-screen-xl lg:mx-32 2xl:mx-auto';

export const thinMargins = `${globalMargins} mx-8 md:mx-16 `;

export const wideMargins = `${globalMargins} mx-8 md:mx-32 `;
