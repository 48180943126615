import * as React from 'react';
import {SocialCard} from './SocialCard';

import instagram from '../../images/socials/instagram-tile.svg';
import tiktok from '../../images/socials/tiktok-tile.svg';
import youtube from '../../images/socials/youtube-tile.svg';
import linkedin from '../../images/socials/linked-tile.svg';

export const Socials = () => {
  return (
    <div className="flex gap-3 pb-3 pt-2 ">
      <SocialCard
        url="https://www.instagram.com/wearelunaapp/?hl=en"
        socialImg={instagram}
      />
      <SocialCard
        url="https://www.tiktok.com/@wearelunaapp"
        socialImg={tiktok}
      />
      <SocialCard
        url="https://www.youtube.com/channel/UClUpxDwoNw9mO_b2HVKdvMg"
        socialImg={youtube}
      />
      <SocialCard
        url="https://www.linkedin.com/company/lunalifeltd/"
        socialImg={linkedin}
      />
    </div>
  );
};
