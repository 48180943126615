import * as React from 'react';

interface IBlockHeader {
  title: string;
  colour: string;
  img?: string;
  imgAlt?: string;
  imgStyling?: string;
  children?: React.ReactNode;
}

export const BlockHeader = ({
  title,
  colour,
  img,
  imgAlt,
  imgStyling,
  children,
}: IBlockHeader) => {
  return (
    <div className={`pt-12 text-black ${colour}`}>
      <div
        className={`flex flex-col-reverse lg:flex-row justify-between max-w-screen-xl items-center py-8 md:py-16 mx-8 lg:mx-28 xl:mx-40 2xl:mx-auto gap-4`}>
        <div className="lg:w-1/2">
          <h1 className="text-h2mob lg:text-h2 font-title py-6 text-center lg:text-left">
            {title}
          </h1>
          {children}
        </div>

        <div className="lg:w-1/2">
          {img && (
            <img
              src={img}
              className={`ml-auto sm:max-w-[28rem] xl:max-w-[32rem] rounded-[2rem] object-cover ${imgStyling}`}
              alt={imgAlt ? imgAlt : `an image of ${img}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
