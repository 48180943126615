import {IGatsbyImageData} from 'gatsby-plugin-image';
import {StructuredTextDocument} from 'react-datocms';

import {PremiumProduct as StripePremiumProduct} from '../../plugins/gatsby-source-luna-firebase/src/types';

export type Page =
  | 'home'
  | 'parents'
  | 'parents-article'
  | 'teens'
  | 'download'
  | 'privacy-policy'
  | 'terms-conditions'
  | 'about-us'
  | 'work-with-us'
  | 'applink'
  | 'articles'
  | 'article'
  | 'questions'
  | 'question'
  | 'premium'
  | 'premium-transaction'
  | 'reports'
  | 'shop'
  | 'schools'
  | 'newsletter-sign-up'
  | 'USA-waitlist'
  | '404';

export type AskLunaQuestion = {
  question: string;
  answer: StructuredTextDocument;
  categories: Category[];
  categoryTitle: string;
};

export type LearnArticle = {
  title: string;
  categoryTitle: string;
  categoryColor: string;
  subtitle: string;
  image: {
    alt?: string;
    gatsbyImageData: IGatsbyImageData;
  };
  categories: Category[];
};

export type TextImageBlock = {
  title: string;
  description: string;
  image?: {
    alt?: string;
    gatsbyImageData: IGatsbyImageData;
  };
};

export type Faq = {
  question: string;
  answer: string;
};

export type ParentReview = {
  title: string;
  reviewerName: string;
  review: {value: StructuredTextDocument};
};

export type CommunityReview = {
  title: string;
  review: string;
  starCount: number;
};

export type Person = {
  image: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  name: string;
  description: string;
  pronouns?: string;
};

export type UserInfo = {
  title: string;
  emoji: string;
  caption: string;
};

export type TitleSubtitle = {
  title: string;
  subtitle: string;
};

export type Category = {
  id: string;
  title: string;
  color: {hex: string};
};

export type Feature = {
  title: string;
  description: string;
  explainer?: {value: StructuredTextDocument};
  image: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
};

export type PremiumFeature = {
  id: string;
  order: number | null;
  title: string;
  includedInFreeTier: boolean;
  includedInPremiumTier: boolean;
  freeTierSpecifics?: string;
  premiumTierSpecifics?: string;
};

export type PremiumProduct = {
  id: string;
  order: number | null;
  name: string;
  price: string;
  description: string;
  tag?: string;
  ctaBuy: string;
  stripePriceLookupKey: string;
};

export {StripePremiumProduct};

export type Report = {
  title: string;
  image: {
    alt?: string;
    gatsbyImageData: IGatsbyImageData;
  };
  summary?: string;
  url: string;
  reportId: string;
};

export type ShopItem = {
  price: number;
  title: string;
  description: string;
  introductory?: boolean;
  image: {
    alt?: string;
    gatsbyImageData: IGatsbyImageData;
  };
  linkUrl: string;
};

export type DatoImage = {
  alt: string;
  gatsbyImageData: IGatsbyImageData;
};

export type Reference = {
  title: string;
  url: string;
};

export type Segment = 'newsletter_parent' | 'newsletter_general';

export type ParentPayStep = {
  title: string;
  description: string;
  img: {
    alt?: string;
    gatsbyImageData: IGatsbyImageData;
  };
};

export enum ButtonTracking {
  AppStoreDownload = 'AppStoreDownload',
}
