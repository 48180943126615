export type CookieConsentStatus = 'accepted' | 'declined';

export function getCookieConsentStatusValue(): CookieConsentStatus | null {
  const value = localStorage.getItem('cookieStatus');
  if (value === 'accepted' || value === 'declined') {
    return value;
  }
  return null;
}

export function setCookieConsentStatusValue(status: CookieConsentStatus) {
  localStorage.setItem('cookieStatus', status);
}
