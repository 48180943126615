import {useMediaQuery} from 'usehooks-ts';

// we can add more screen sizes to this as and when we need them
type ScreenSize = 'small' | 'medium' | 'large' | 'extra-large' | 'xx-large';

export function useScreenSize(): ScreenSize {
  // note: mobile first approach means we can describe screen sizes based on  min width
  // breakpoints and augment accordingly
  // EG use this to apply styles/side effects on devices that are AT LEAST "medium" in size
  const mediumScreen = useMediaQuery('(min-width: 767px)');
  const largeScreen = useMediaQuery('(min-width: 1024px)');
  const xlScreen = useMediaQuery('(min-width: 1280px)');
  const xxlScreen = useMediaQuery('(min-width: 1536px)');

  if (xxlScreen) {
    return 'xx-large';
  } else if (xlScreen) {
    return 'extra-large';
  } else if (largeScreen) {
    return 'large';
  } else if (mediumScreen) {
    return 'medium';
  } else return 'small';
}
