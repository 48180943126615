import {IGatsbyImageData} from 'gatsby-plugin-image';
import {AskLunaQuestion, Category, LearnArticle} from '../types';

export const findRelatedArticles = (
  articleList: LearnArticle[],
  category: Category,
): LearnArticle[] => {
  const categoryArticleList = [] as LearnArticle[];

  articleList.map(article => {
    if (article.categories.find(c => c.id === category.id)) {
      categoryArticleList.push(article);
    }
  });

  return categoryArticleList;
};

export const findRelatedQuestions = (
  questionList: AskLunaQuestion[],
  category: Category,
): AskLunaQuestion[] => {
  const categoryQuestionList = [] as AskLunaQuestion[];

  questionList.map(question => {
    if (question.categories.find(c => c.id === category.id)) {
      categoryQuestionList.push(question);
    }
  });

  return categoryQuestionList;
};
